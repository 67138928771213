import React, { useEffect, useState } from "react";
import "./dashboard-financial-wellness-articles.scss";
import { useSelector } from "../../../store/store";
import "./article-details.scss";
import {
  financialWellnessArticlesStatus,
  useStatuses,
} from "../../../selectors/status";
import { FinancialWellnessArticle } from "../../../models/dashboard/financial-wellness-articles";
import { ArticleDetails } from "./article-details";

type dashboardFinancialArticleProps = {
  financialArticles?: FinancialWellnessArticle[];
};

export function DashboardFinancialWellnessArticles(
  props: dashboardFinancialArticleProps
) {
  const { financialArticles } = props;
  const [selectedFilters, setSelectedFilters] = useState(["all-types"]);
  const [numberOfArticles, setNumberOfArticles] = useState(9);
  const [isShowMoreArticlesClicked, setIsShowMoreArticlesClicked] =
    useState(false);
  const { isLoadingStatus, hasError } = useStatuses([
    financialWellnessArticlesStatus,
  ]);

  const { financialWellnessArticles } = useSelector(
    (state) => state.financialWellnessArticles
  );

  let filteredFinancialWellnessArticles;
  let filterTags: string[] = [];
  if (financialArticles !== undefined) {
    filteredFinancialWellnessArticles =
      financialArticles.filter(filterArticles);
    filterTags = getTags(financialArticles);
  } else {
    filteredFinancialWellnessArticles =
      financialWellnessArticles.filter(filterArticles);
    filterTags = getTags(financialWellnessArticles);
  }

  useEffect(() => {}, [isShowMoreArticlesClicked]);

  if (isLoadingStatus) {
    return <p className="fwa-text">Loading financial wellness articles...</p>;
  }
  if (hasError) {
    return (
      <>
        <div className="fwa-title">Financial wellness</div>
        <p className="fwa-text">
          Unable to load financial wellness articles. Please try again later.
        </p>
      </>
    );
  }

  const handleClick = (filter: string) => {
    if (filter !== "all-types") {
      if (selectedFilters.includes("all-types")) {
        const filterArr = [...selectedFilters];
        filterArr.splice(filterArr.indexOf("all-types"), 1);
        setSelectedFilters(filterArr);
        if (!selectedFilters.includes(filter)) {
          const filterArr = [filter];
          setSelectedFilters(filterArr);
        }
      } else {
        if (!selectedFilters.includes(filter)) {
          setSelectedFilters(selectedFilters.concat(filter));
        } else if (
          selectedFilters.includes(filter) &&
          selectedFilters.length !== 1
        ) {
          const filterArr = [...selectedFilters];
          filterArr.splice(filterArr.indexOf(filter), 1);
          setSelectedFilters(filterArr);
        } else if (
          selectedFilters.includes(filter) &&
          selectedFilters.length === 1
        ) {
          if (!selectedFilters.includes("all-types")) {
            const filterArr = ["all-types"];
            setSelectedFilters(filterArr);
          }
        }
      }
    } else {
      if (!selectedFilters.includes(filter)) {
        const filterArr = [filter];
        setSelectedFilters(filterArr);
      }
    }
  };

  function filterArticles(financialWellnessArticle: FinancialWellnessArticle) {
    let str = "";
    for (const filter of selectedFilters) {
      if (filter === `${financialWellnessArticle.filterTag}-types`) {
        str = "true";
      }
    }
    return str;
  }

  function getTags(financialWellnessArticles: FinancialWellnessArticle[]) {
    let listToReturn: string[] = [];
    for (let i = 0; i < financialWellnessArticles.length; i++) {
      const filter = financialWellnessArticles[i].filterTag;
      if (!listToReturn.includes(filter)) {
        listToReturn = listToReturn.concat(filter);
      }
    }
    return listToReturn;
  }

  function displayMoreArticles() {
    setIsShowMoreArticlesClicked(true);
    setNumberOfArticles(financialWellnessArticles.length);
  }

  function isAllTypesSelected() {
    return (
      selectedFilters.length === 1 && selectedFilters.includes("all-types")
    );
  }

  return (
    <section className={"financial-articles row"}>
      <div className="fwa-title">Financial Wellness</div>
      <div className="fwa-text">
        We know there's a lot to think about when it comes to your finances. Get
        help on the topics you're interested in with resources from Principal
        <sup>
          <span>&#174; </span>
        </sup>
        Milestones.{" "}
      </div>
      <div className="fwa-text">
        You'll find articles, videos, calculators, worksheets, and more, all
        designed to help you navigate your financial journey, wherever you are
        on the pathway.
      </div>
      <div>
        <div className="col-md-2">
          <h1 className={"h3 text-white"}>
            Select topics that you're interested in to filter your results.
          </h1>
          <div>
            <FilterButton
              handleClick={handleClick}
              selectedFilter={selectedFilters}
              filter={"all-types"}
            >
              All topics
            </FilterButton>
          </div>
          {filterTags.map((filterTag) => {
            return (
              <FilterButton
                key={filterTag}
                handleClick={handleClick}
                selectedFilter={selectedFilters}
                filter={`${filterTag}-types`}
              >
                {filterTag}
              </FilterButton>
            );
          })}
        </div>
        <div className="col-md-10">
          <div className={"masonry"}>
            {isAllTypesSelected()
              ? financialWellnessArticles?.map(
                  (article, index) =>
                    index < numberOfArticles && (
                      <div className="article-card" key={article.articleTitle}>
                        <ArticleDetails
                          financialWellnessArticle={article}
                          index={index}
                        />
                      </div>
                    )
                )
              : filteredFinancialWellnessArticles?.map(
                  (article, index) =>
                    index < numberOfArticles && (
                      <div className="article-card" key={article.articleTitle}>
                        <ArticleDetails
                          financialWellnessArticle={article}
                          index={index}
                        />
                      </div>
                    )
                )}
          </div>
        </div>
      </div>
      {!isShowMoreArticlesClicked && (
        <div className={"div-styling"}>
          <button
            className={"dropdown-button"}
            onClick={() => displayMoreArticles()}
          >
            <div className={"txt"}>Show more</div>
            <i className={"fa fa-chevron-down"} />
          </button>
        </div>
      )}
    </section>
  );
}

type FilterButtonProps = {
  filter: string;
  selectedFilter: string[];
  handleClick: (filter: string) => void;
  children: React.ReactNode;
};

function FilterButton(props: FilterButtonProps) {
  const { filter, selectedFilter, handleClick } = props;
  let isSelectedFilter;
  if (selectedFilter.includes(filter)) {
    isSelectedFilter = filter;
  }

  return (
    <button
      type="button"
      id={`${filter}-button`}
      data-testid={`${filter}-button`}
      className={`fwa-button ${isSelectedFilter ? "fwa-button-active" : ""}`}
      onClick={() => {
        handleClick(filter);
      }}
      aria-pressed={isSelectedFilter ? "true" : "false"}
    >
      {props.children}
    </button>
  );
}

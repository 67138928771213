import React from "react";
import { useParams } from "react-router";
import { Page } from "src/components/page";
import { Skeleton } from "../../../components/skeleton/skeleton";
import { RedeferralStatus } from "../../../models/redeferral/redeferral-status";
import { accountRedeferralStatusesSelector } from "../../../selectors/redeferrals";
import { accountRedeferralStatusesStatusSelector } from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import { DISTRIBUTION_REDEFERRAL_DISCLAIMER } from "../../../util/constants";
import RedeferBeginStreamOfPayment from "./redefer-begin-stream-of-payment";
import RedeferNavHeader from "./redefer-nav-header";
import { getRedeferralFlowType, RedeferralType } from "./redefer-type-enum";
import { getRedeferralStateForAccount } from "./redefer-util";
import RedeferBeginIndividualPayment from "./redefer-individual/redefer-begin-individual-payment";

export default function RedeferBeginPage() {
  const { accountId } = useParams();

  const allRedeferralStates: RedeferralStatus[] = useSelector(
    accountRedeferralStatusesSelector
  );
  const accountRedeferralState = getRedeferralStateForAccount(
    allRedeferralStates,
    accountId
  );
  const redeferralType = getRedeferralFlowType(accountRedeferralState);

  return (
    <Page name={"redefer-begin"} loading={false}>
      <RedeferNavHeader
        stepNumber={1}
        accountRedeferralState={accountRedeferralState}
      />
      <Skeleton
        selectors={[accountRedeferralStatusesStatusSelector]}
        height={90}
        width={180}
      >
        {accountRedeferralState && (
          <>
            {redeferralType === RedeferralType.NOT_SET && (
              <div className="row">
                <div className="col-md-12">
                  <p>
                    This redeferral type (
                    {accountRedeferralState.redeferralType}) is not allowed.
                  </p>
                </div>
              </div>
            )}
            {redeferralType !== RedeferralType.NOT_SET && (
              <div id="begin-form" className="row">
                <div className="col-md-12">
                  {redeferralType === RedeferralType.STREAM_OF_PAYMENT && (
                    <RedeferBeginStreamOfPayment
                      status={accountRedeferralState}
                    />
                  )}
                  {redeferralType === RedeferralType.INDIVIDUAL_PAYMENT && (
                    <RedeferBeginIndividualPayment
                      status={accountRedeferralState}
                    />
                  )}
                </div>
                <div className="col-md-12">
                  <hr />
                  {redeferralType === RedeferralType.INDIVIDUAL_PAYMENT && (
                    <p>
                      The Estimated Distribution amount(s) listed are based upon
                      your current account balance and do not factor in a
                      vesting schedule, if applicable. Your actual distribution
                      may be different than the amount(s) listed above.
                    </p>
                  )}
                  <p>{DISTRIBUTION_REDEFERRAL_DISCLAIMER}</p>
                </div>
              </div>
            )}
          </>
        )}
      </Skeleton>
    </Page>
  );
}

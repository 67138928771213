import axios from "axios";
import { enterpriseApiKey } from "../util/constants";

const basePath = "/enterprise";
const buildPath = (path: string) => `${basePath}${path}`;

export async function get(path: string, paramsOverrides = {}) {
  const { data } = await axios.get(buildPath(path), {
    params: { key: enterpriseApiKey, ...paramsOverrides },
  });

  return data;
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Page } from "src/components/page";
import DebugCard from "../../../components/card/debug-card";
import { Skeleton } from "../../../components/skeleton/skeleton";
import { RedeferralStatus } from "../../../models/redeferral/redeferral-status";
import { accountRedeferralStatusesSelector } from "../../../selectors/redeferrals";
import {
  accountRedeferralStatusesStatusSelector,
  redeferralInstructionDtosStatus,
  useStatuses,
} from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import { DISTRIBUTION_REDEFERRAL_DISCLAIMER } from "../../../util/constants";
import { buildLinkForStreamOfPaymentPending } from "../../../util/nq-participant-link-builder";
import RedeferChangeDisplay from "./redefer-change-display";
import RedeferNavHeader from "./redefer-nav-header";
import { getRedeferralStateForAccount } from "./redefer-util";
import { getRedeferralInstructionDTOsForAccount } from "../../../services/redeferral";
import { RedeferralInstructionDto } from "../../../models/redeferral/redeferral-instruction-dto";
import { formatDateWithoutTimeZone, parseDate } from "../../../util/date-util";
import { fetchRedeferralInstructionDtos } from "../../../reducers/redeferral/redeferral-instruction-dto";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/cjs/Button";
import BSModal from "react-bootstrap/Modal";

export default function RedeferConfirmPage() {
  const dispatch = useDispatch();
  const { isLoadingStatus } = useStatuses([redeferralInstructionDtosStatus]);
  const [redeferralInstruction, setRedeferralInstruction] =
    useState<RedeferralInstructionDto>();
  const [effectiveDate, setEffectiveDate] = useState<string | undefined>("");
  const [newName, setNewName] = useState<string | undefined>("");
  const [newMethod, setNewMethod] = useState<string | undefined>("");
  const [newDate, setNewDate] = useState<string | undefined>("");

  const { accountId, instructionId } = useParams();
  const allRedeferralStates: RedeferralStatus[] = useSelector(
    accountRedeferralStatusesSelector
  );
  const accountRedeferralState = getRedeferralStateForAccount(
    allRedeferralStates,
    accountId
  );

  const { empId, planId, role } = useSelector((state) => state.session);
  const pendingDistributionLink = buildLinkForStreamOfPaymentPending(
    planId,
    empId,
    accountId,
    role
  );

  useEffect(() => {
    fetchRedeferralInstructions();

    async function fetchRedeferralInstructions() {
      const data = await getRedeferralInstructionDTOsForAccount(
        role,
        accountId
      );
      if (data) {
        const redeferralInstruction = getRedeferralInstruction(data);
        if (redeferralInstruction) {
          setRedeferralInstruction(redeferralInstruction);
          const parsedEffectiveDate = parseDate(
            redeferralInstruction.effectiveAsOfDate
          );
          const parsedPayoutDate = parseDate(
            redeferralInstruction.payoutStartDate
          );
          setEffectiveDate(
            formatDateWithoutTimeZone(parsedEffectiveDate, "MM/dd/yyyy")
          );
          setNewDate(formatDateWithoutTimeZone(parsedPayoutDate, "MM/dd/yyyy"));
          setNewName(redeferralInstruction.accountName);
          setNewMethod(redeferralInstruction.distributionMethodDisplay);
        }
      }
    }
  }, []);

  useEffect(() => {
    dispatch(
      fetchRedeferralInstructionDtos({
        accountId,
        role,
      })
    );
  }, []);

  function getRedeferralInstruction(
    redeferralInstructionDtos: RedeferralInstructionDto[]
  ): RedeferralInstructionDto | undefined {
    return redeferralInstructionDtos.find((instruction) => {
      return instruction.id === Number(instructionId);
    });
  }

  function getDistributionSummaryLink() {
    return "/distributions/summary";
  }

  return (
    <Page name={"redefer-begin"} loading={isLoadingStatus} printable>
      <RedeferNavHeader
        stepNumber={3}
        accountRedeferralState={accountRedeferralState}
      />
      <Skeleton
        selectors={[accountRedeferralStatusesStatusSelector]}
        height={90}
        width={180}
      >
        {accountRedeferralState && (
          <>
            <div className="row">
              <div className="col-md-12">
                <DebugCard>
                  <p>
                    <strong>Instruction id:</strong> {instructionId}
                  </p>
                </DebugCard>
                <p>
                  Your request to change your distribution election(s) has been
                  received. These changes will become effective as of{" "}
                  {effectiveDate}. You may click here to view your changes:{" "}
                  <Link to={pendingDistributionLink}>
                    view your pending distribution elections.
                  </Link>
                </p>
                <RedeferChangeDisplay
                  newDate={newDate}
                  newMethod={newMethod}
                  newName={newName}
                  effectiveDate={effectiveDate}
                  accountRedeferralStatus={accountRedeferralState}
                  isConfirm={true}
                />
                <hr />
                <a
                  className="btn btn-primary pull-right"
                  href={getDistributionSummaryLink()}
                >
                  Back to distributions <i className="fa fa-arrow-right" />
                </a>
              </div>
              <div className="col-md-12">
                <hr />
                {DISTRIBUTION_REDEFERRAL_DISCLAIMER}
              </div>
            </div>
          </>
        )}
      </Skeleton>
    </Page>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { PrrModal } from "../../../pages/prr-faq/prr-modal";
import { VestedInfoModal } from "../../../pages/vested-info/vested-info-modal";
import { InvestmentRoutes, OverviewRoutes } from "../../../routes/routes";
import { getStartOfYear } from "../../../util/date-util";
import { AccountOverviewRows } from "./AccountOverviewRows";
import { NqAccount } from "../../../models/nq-accounts.model";

type AccountDetailProps = {
  name: AccountOverviewRows;
  url: OverviewRoutes | InvestmentRoutes;
  type: (props: any) => JSX.Element;
  value?: number;
  account?: NqAccount;
};

export function AccountDetail(props: AccountDetailProps) {
  const { type: Component, name, value, url, account } = props;

  return (
    <div className="detail-row clickable">
      <Link to={url} data-testid={name} className="name-link">
        <span className="pre-wrap">{name}</span>
        <SinceStartOfYear name={name} />
      </Link>
      <div className="dashboard-account-modal hidden-sm">
        <InfoLinkIcon name={name} />
      </div>
      <Link to={url} data-testid={`${name}-value`} className="arrow-link">
        <AccountBalance
          name={name}
          url={url}
          type={Component}
          value={value}
          account={account}
        />
        <i
          className="fa fa-angle-right fa-2x text-primary"
          aria-hidden="true"
        />
      </Link>
    </div>
  );
}

type AccountContributionDetailProps = {
  name: AccountOverviewRows;
  type: (props: any) => JSX.Element;
  value?: number;
  date: string;
};

export function AccountContributionDetail(
  props: AccountContributionDetailProps
) {
  const { type: Component, name, value, date } = props;
  const formattedDate = date.replace(/-/gi, "/");

  return (
    <div className="detail-row">
      <div data-testid={name} className="name-link">
        <span className="pre-wrap">{name}</span>
        <span className="since-start-of-year">
          <small>on {formattedDate}</small>
        </span>
      </div>
      <div className="dashboard-account-modal hidden-sm">
        <InfoLinkIcon name={name} />
      </div>
      <div data-testid={`${name}-value`}>
        <Component value={value} showDollar />
      </div>
    </div>
  );
}

type RowProps = { name: AccountOverviewRows };

function SinceStartOfYear(props: RowProps) {
  const { name } = props;
  if (
    name === AccountOverviewRows.BEGIN ||
    name === AccountOverviewRows.PRR ||
    name === AccountOverviewRows.GL
  ) {
    const currentYear = getStartOfYear().getFullYear();
    return (
      <span className="since-start-of-year">
        <small>Since 1/1/{currentYear}</small>
      </span>
    );
  }

  return null;
}

function InfoLinkIcon(props: RowProps) {
  const { name } = props;
  if (name === AccountOverviewRows.VESTED) {
    return <VestedInfoModal />;
  }
  if (name === AccountOverviewRows.PRR) {
    return <PrrModal linkText={<span className="sr-only">PRR FAQ</span>} />;
  }

  return null;
}

export function AccountBalance(props: AccountDetailProps) {
  const { type: Component, value } = props;

  return (
    <>
      <Component value={value} showDollar />
    </>
  );
}

interface AccountBalanceProps {
  account: NqAccount;
}

export function LiabilityCalcAccountBalanceVested(props: AccountBalanceProps) {
  const { account } = props;

  if (account.hasBalance) {
    return <>{account.balances[0].vestedBalanceText}</>;
  } else {
    return <>$0.00</>;
  }
}

interface AccountBalanceProps {
  account: NqAccount;
}

export function LiabilityCalcAccountBalanceTotal(props: AccountBalanceProps) {
  const { account } = props;

  if (account.hasBalance) {
    return <>{account.balances[0].totalBalanceText}</>;
  } else {
    return <>$0.00</>;
  }
}

import React, { useEffect } from "react";
import AccountName from "../../../../components/account-name/account-name";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import { accountsStatusSelector } from "../../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../../store/store";
import FilterInvestmentsModal from "./filter-investments-modal/filter-investments-modal";
import "./summary-filter.scss";
import { getDashFormattedDateStringFromISOString } from "../../../../util/date-util";
import { useDates } from "../../../../selectors/dates";
import { setInvestmentSummaryDates } from "../../../../reducers/dates";

export default function SummaryFilter() {
  const { available: accounts, selected: selectedAccounts } = useSelector(
    (state) => state.accounts
  );
  const {
    startDate,
    endDate,
    investmentSummaryStartDate,
    investmentSummaryEndDate,
    lastBusinessDate,
  } = useDates();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setInvestmentSummaryDates({
        investmentSummaryStartDate: startDate!,
        investmentSummaryEndDate: endDate!,
      })
    );
  }, []);

  return (
    <>
      <div className="summary-filter" data-testid="summary-filter">
        <h2 className="h5">Customize your view</h2>
        <div className="row">
          <div className="col-xs-4">
            <strong>Dates:</strong>
            <div data-testid="dates">
              <Skeleton selectors={[accountsStatusSelector]} height={24}>
                {!investmentSummaryStartDate && (
                  <>
                    {getDashFormattedDateStringFromISOString(startDate!)} -{" "}
                    {getDashFormattedDateStringFromISOString(endDate!)}
                  </>
                )}
                {investmentSummaryStartDate && (
                  <>
                    {getDashFormattedDateStringFromISOString(
                      investmentSummaryStartDate!
                    )}{" "}
                    -{" "}
                    {getDashFormattedDateStringFromISOString(
                      investmentSummaryEndDate!
                    )}
                  </>
                )}
              </Skeleton>
            </div>
          </div>
          <div className="col-xs-4">
            <strong>Accounts:</strong>
            <div data-testid="accounts">
              <Skeleton
                selectors={[accountsStatusSelector]}
                height={24}
                count={1}
              >
                {accounts.length === selectedAccounts.length
                  ? "Viewing all account(s)"
                  : renderAccounts(selectedAccounts)}
              </Skeleton>
            </div>
          </div>
          <div className="col-xs-4">
            <FilterInvestmentsModal />
          </div>
        </div>
      </div>
      <p data-testid="info-text">
        The following information is updated as of&nbsp;
        <Skeleton selectors={[accountsStatusSelector]} height={24} count={1}>
          {<>{getDashFormattedDateStringFromISOString(lastBusinessDate!)}</>}
        </Skeleton>
      </p>
    </>
  );
}

function renderAccounts(selectedAccounts: any) {
  return (
    <>
      <ul>
        {selectedAccounts.map((account: any, index: any) => {
          return (
            <li key={index} data-testid={`selectedAccount-${account.id}`}>
              <AccountName name={account.name} id={account.id} asLink={false} />
            </li>
          );
        })}
      </ul>
    </>
  );
}

import React from "react";
import "./article-details.scss";
import { FinancialWellnessArticle } from "../../../models/dashboard/financial-wellness-articles";

export type ArticleDetailsProps = {
  financialWellnessArticle: FinancialWellnessArticle;
  index: number;
};

export function ArticleDetails(props: ArticleDetailsProps) {
  const { financialWellnessArticle } = props;

  function goToLink() {
    const url = financialWellnessArticle.articleLink;
    (window as any).dataLayer.push({
      event: "navigationClick",
      category: financialWellnessArticle.filterTag,
      clicktext: financialWellnessArticle.articleTitle,
      label: "Clicked: " + url,
    });
    window.open(url);
  }

  return (
    <div className="article-background">
      <div className="article-header">
        {financialWellnessArticle.articleTitle}
      </div>
      <div className="article-type">{financialWellnessArticle.articleType}</div>
      <div>
        <span className="article-filter">
          {financialWellnessArticle.filterTag}
        </span>
      </div>
      <div className="article-description">
        {financialWellnessArticle.articleDescription}
      </div>
      <a>
        <button onClick={() => goToLink()} className="article-button-link">
          {financialWellnessArticle.articleLinkText}
        </button>
        <span className="sr-only">
          {" "}
          - {financialWellnessArticle.articleTitle}
        </span>
      </a>
    </div>
  );
}

import React, { useEffect } from "react";
import { fetchParticipantDetails } from "../../reducers/participant";

import { useAppDispatch, useSelector } from "../../store/store";
import { logoutPath } from "../../util/constants";

export function PlanHeaderTransition() {
  const dispatch = useAppDispatch();
  const { empId, planId, role } = useSelector((state) => state.session);
  const { planName } = useSelector((state) => state.plan.info);
  const personalDetails = useSelector((state) => state.participant.data);

  useEffect(() => {
    dispatch(fetchParticipantDetails({ planId, empId, role }));
  }, [dispatch, empId, planId, role]);
  return (
    <>
      <div className="row">
        <div className="col-md-3 col-md-offset-9 hidden-xs hidden-sm hidden-print text-right util-margin-top-15">
          Welcome {personalDetails.firstName} - <a href={logoutPath}>Log out</a>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <img
            src="https://www.principalcdn.com/css/horizon/v2/logo-onecolor-right.svg"
            alt="principal p logo"
            width="150px"
          />
        </div>
        <div className="col-md-4 text-right">
          <p className="util-margin-top-10">{planName}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h1 className="text-center text-midnight h2">Welcome!</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <p className="text-center">
            We're so glad you are here. Now's a great time to check out your
            account and your new experience. It's also a great time to see if
            you're on track with your retirement goals
          </p>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PrrAccordion from "../../../components/accordion/prr-accordion";
import { Page, PageHeader } from "../../../components/page";
import AssetClassChart from "../../../pages/investments/summary/asset-chart/asset-class-chart";
import { useInvestments } from "../../../selectors/investments";
import { hidePrrSelector } from "../../../selectors/plan-info";
import {
  datesStatusSelector,
  investmentsStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../store/store";
import { PlanOverviewDisclaimer } from "../disclaimers/plan-overview-disclaimers";
import "./plan-overview-page.scss";
import PlanOverviewTable from "./plan-overview-table";
import { LiabilityCalcAccountBalanceDate } from "../../../components/dashboard/dashboard-account-overview/dashboard-account-overview";
import { setShowPaidOutAccounts } from "../../../reducers/accounts";

export default function PlanOverviewPage() {
  const hidePrr = useSelector(hidePrrSelector);
  const dispatch = useAppDispatch();
  const { data: nqAccountData } = useSelector(
    (state) => state.nqAccountDetailsSlice
  );
  const [buttonText, setButtonText] = useState("View Paid Out Accounts");
  const { showPaidOutAccounts, paidOutAccountsExist, allAccountsPaidOut } =
    useSelector((state) => state.accounts);
  const { investments } = useInvestments();
  const { isLoadingStatus } = useStatuses([
    investmentsStatusSelector,
    datesStatusSelector,
  ]);
  useEffect(() => {
    if (showPaidOutAccounts) {
      setButtonText("Hide Paid Out Accounts");
    } else {
      setButtonText("View Paid Out Accounts");
    }
  }, [showPaidOutAccounts]);

  function handleViewPaidOutAccountsButtonClick() {
    if (!showPaidOutAccounts) {
      dispatch(setShowPaidOutAccounts(true));
    } else {
      dispatch(setShowPaidOutAccounts(false));
    }
  }

  return (
    <Page name="balances-by-account" printable loading={isLoadingStatus}>
      <PageHeader name="balances by account">Your Plan Overview</PageHeader>
      <div>
        <p style={{ display: "inline-block" }}>
          The following information is as of{" "}
          {nqAccountData && (
            <LiabilityCalcAccountBalanceDate account={nqAccountData} />
          )}
        </p>
        {paidOutAccountsExist && !allAccountsPaidOut && (
          <button
            type="button"
            className="btn btn-primary paid-out-accounts-button"
            onClick={handleViewPaidOutAccountsButtonClick}
          >
            {buttonText}
          </button>
        )}
      </div>
      <div className="plan-overview">
        <div className="plan-overview-table">
          <PlanOverviewTable showPaidOutAccounts={showPaidOutAccounts} />
        </div>
        <div className="plan-overview-chart">
          <AssetClassChart headingLevel="2" investments={investments} />
        </div>
      </div>
      <div className="disclaimer">
        <PlanOverviewDisclaimer />
        {!hidePrr && <PrrAccordion />}
      </div>
    </Page>
  );
}

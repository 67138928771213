import React, {useEffect} from "react";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import {Page} from "src/components/page";
import DebugSpan from "../../../components/card/debug-span";
import {Skeleton} from "../../../components/skeleton/skeleton";
import {RedeferralStatus} from "../../../models/redeferral/redeferral-status";
import {DistributionRoutes} from "../../../routes/routes";
import {accountRedeferralStatusesSelector} from "../../../selectors/redeferrals";
import {accountRedeferralStatusesStatusSelector} from "../../../selectors/status";
import {deleteRedeferralInstruction} from "../../../services/redeferral";
import {useSelector} from "../../../store/store";
import {getRedeferLink, getRedeferralStateForAccount, getRedeferralTypeDisplayName,} from "./redefer-util";
import {useDispatch} from "react-redux";
import {fetchRedeferralInstructionDtos} from "../../../reducers/redeferral/redeferral-instruction-dto";

export default function RedeferAccountPage() {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const { contractId } = useSelector((state) => state.plan.info);
  const { empId, role } = useSelector((state) => state.session);
  const { redeferralInstructionDTOs } = useSelector(
    (state) => state.redeferrals.redeferralInstructionDtos
  );
  const allRedeferralStates: RedeferralStatus[] = useSelector(
    accountRedeferralStatusesSelector
  );
  const accountRedeferralState = getRedeferralStateForAccount(
    allRedeferralStates,
    accountId
  );
  const noOptionsAvailable =
    accountRedeferralState &&
    !accountRedeferralState.showPendingRedeferralLink &&
    !accountRedeferralState.showRedeferLink;
  const redeferralType = getRedeferralTypeDisplayName(accountRedeferralState);
  const redeferLink = getRedeferLink(
    accountRedeferralState,
    empId,
    contractId,
    accountId,
    role
  );

  async function removeInstruction(instructionId: number) {
    await deleteRedeferralInstruction(role, instructionId);
    window.location.reload();
  }

  useEffect(() => {
    dispatch(
      fetchRedeferralInstructionDtos({
        accountId,
        role,
      })
    );
  }, [role, accountId]);

  return (
    <Page name={"redefer-account"} loading={false}>
      <p>
        <small>
          <Link to={DistributionRoutes.RedeferralsSummary}>
            <i className="fa fa-arrow-left"></i>
            See all redeferral states
          </Link>
        </small>
      </p>
      <h1>Your account redeferral status</h1>
      <h2>
        {accountRedeferralState && (
          <>for {accountRedeferralState.accountName}</>
        )}
        {!accountRedeferralState && <>for account id {accountId}</>}
      </h2>
      <Skeleton
        selectors={[accountRedeferralStatusesStatusSelector]}
        height={90}
        width={100}
      >
        {accountRedeferralState === undefined && <p>Not found.</p>}
        {accountRedeferralState !== undefined && (
          <>
            {noOptionsAvailable && (
              <>
                <p>No redeferral options available.</p>
              </>
            )}
            {accountRedeferralState.showPendingRedeferralLink &&
              !accountRedeferralState.showRedeferLink && (
                <>
                  <p>Pending redeferral available.</p>
                  <p>
                    <a className="btn btn-default" href={redeferLink}>
                      View pending redeferral
                      <i className="fa fa-arrow-right util-margin-left-5"></i>
                    </a>
                  </p>
                </>
              )}
            {accountRedeferralState.showRedeferLink && (
              <>
                <p>Active redeferral ({redeferralType}) available.</p>
                <p>
                  <a className="btn btn-default" href={redeferLink}>
                    Redefer now (old flow, current)
                    <i className="fa fa-arrow-right util-margin-left-5"></i>
                  </a>
                </p>
                <p>
                  <Link
                    className="btn btn-primary"
                    to={
                      DistributionRoutes.RedeferralBegin +
                      "/" +
                      accountRedeferralState.accountId
                    }
                  >
                    Redefer now (new flow, work in progress)
                    <i className="fa fa-arrow-right util-margin-left-5"></i>
                  </Link>
                </p>
              </>
            )}
            <hr />
            <h3>Redeferral instructions</h3>
            {!redeferralInstructionDTOs && <p>Loading...</p>}
            {redeferralInstructionDTOs && redeferralInstructionDTOs.length > 0 && (
              <>
                <ul>
                  {redeferralInstructionDTOs.map((instruction, index) => {
                      let effectiveDate = "?"
                      if (instruction && instruction.effectiveDate) {
                          effectiveDate = instruction.effectiveDate.split("T")[0];
                      }
                    return (
                      <li>
                        #{index + 1} Effective on {effectiveDate}
                        {index === 0 && (
                          <DebugSpan>
                            <button
                              className="btn btn-default btn-sm util-margin-left-10"
                              onClick={async () => {
                                await removeInstruction(instruction.id);
                              }}
                            >
                              <i className="fa fa-trash"></i> Remove
                            </button>
                          </DebugSpan>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
            {redeferralInstructionDTOs &&
              redeferralInstructionDTOs.length === 0 && (
                <>
                  <p>No instructions available.</p>
                </>
              )}
          </>
        )}
      </Skeleton>
    </Page>
  );
}

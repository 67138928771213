import React, { useState } from "react";
import { useLocation } from "react-router";
import { getDebuggerSetting } from "../../util/debug-util";

interface Props {
  children: any;
}

// DebugCard components only show when ?debug=true is in the URL
export default function DebugCard(props: Props) {
  const { children } = props;
  const { search } = useLocation();
  const isDebuggerOn = getDebuggerSetting(search);
  const [showElem, setShowElem] = useState(true);

  return (
    <>
      {isDebuggerOn && showElem && (
        <>
          <div className="debugger alert alert-info util-margin-bottom-0">
            <p>
              {" "}
              {showElem}
              <strong>
                <i className="fa fa-info-circle"></i> DEBUG
              </strong>
              <span
                className="pull-right"
                onClick={() => setShowElem(!showElem)}
              >
                <i className="fa fa-times"></i>
              </span>
            </p>
            {children}
          </div>
        </>
      )}
    </>
  );
}

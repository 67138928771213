import classnames from "classnames";
import React from "react";
import Icon from "../../../components/icon/icon";
import LoadingSpinner from "../../../components/loading-spinner/loading-spinner";
import Percent from "../../../components/numbers/percent";
import { sortedSelectedInvestmentsSelector } from "../../../selectors/investments";
import {
  accountsStatusSelector,
  datesStatusSelector,
  investmentsStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import {
  calculateTotalPRR,
  calculateTotalRateOfReturn,
} from "../../../util/investments";
import "./prr-total-display.scss";
import { getDashFormattedDateStringFromISOString } from "../../../util/date-util";
import { useDates } from "../../../selectors/dates";
import { useLiabilitiesRestApi } from "../../../selectors/feature-flags";

export default function PrrTotalDisplay() {
  const sortedInvestments = useSelector(sortedSelectedInvestmentsSelector);
  const { isLoadingStatus } = useStatuses([
    accountsStatusSelector,
    datesStatusSelector,
    investmentsStatusSelector,
  ]);
  const {
    startDate,
    endDate,
    investmentSummaryStartDate,
    investmentSummaryEndDate,
  } = useDates();
  const useLiabilitiesApi = useSelector(useLiabilitiesRestApi);
  const total = useLiabilitiesApi
    ? calculateTotalPRR(sortedInvestments)
    : calculateTotalRateOfReturn(sortedInvestments);
  const arrowClasses = classnames([
    total >= 0 ? "arrow-up green" : "arrow-down red",
  ]);

  if (isLoadingStatus) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="prr-total-display" data-testid="prr-display">
        <div className="prr-value">
          <Icon name={arrowClasses} />
          &nbsp;
          <Percent value={total} />
        </div>
        <p>
          <strong className="show">From dates</strong>
          {!investmentSummaryStartDate && (
            <>
              {getDashFormattedDateStringFromISOString(startDate!)} through{" "}
              {getDashFormattedDateStringFromISOString(endDate!)}
            </>
          )}
          {investmentSummaryStartDate && (
            <>
              {getDashFormattedDateStringFromISOString(
                investmentSummaryStartDate!
              )}{" "}
              through{" "}
              {getDashFormattedDateStringFromISOString(
                investmentSummaryEndDate!
              )}
            </>
          )}
        </p>
      </div>
    </>
  );
}

import _findKey from "lodash/findKey";

export const roles = {
  MEMBER: ["member", "prtcp", "MEMBER", "prtcpnt"],
  EMPLOYER: ["employer", "emplyr", "EMPLOYER"],
  ADVISOR: ["advisor", "advsr", "ADVISOR"],
};

export function getRoleForString(searchValue: string): string {
  const role = _findKey(roles, (role) => role.includes(searchValue));
  return role ? role : "MEMBER";
}

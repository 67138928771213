import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import qs from "query-string";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Account } from "../../models/account";
import { PayoutStatus } from "../../models/payout-status";
import {
  deselectAll,
  selectAll,
  setSelectedAccounts,
  toggleAccount,
} from "../../reducers/accounts";
import { useSelector } from "../../store/store";
import "./account-dropdown.scss";
import { AccountOption } from "./account-option";
import { hideAccountNumberSelector } from "../../selectors/plan-info";
import Icon from "../icon/icon";

type Props = {
  isSingleSelect?: boolean;
  hidePaidAccounts?: boolean;
  hideAccountsWithNoBalance?: boolean;
  showPaidOutAccounts?: boolean;
};

function AccountDropdown(props: Props) {
  const dispatch = useDispatch();
  const {
    available: accounts,
    selected: selectedAccounts,
    selectedIds: selectedAccountIds,
  } = useSelector((state) => state.accounts);
  const hideAccountNumber = useSelector(hideAccountNumberSelector);

  const { search } = useLocation();
  const { accountId: queryParamAccountId } = qs.parse(search);

  const allAccountsSelected = accounts.length === selectedAccountIds.length;
  const {
    isSingleSelect,
    hidePaidAccounts,
    hideAccountsWithNoBalance,
    showPaidOutAccounts,
  } = props;
  let filteredAccounts: Account[] = [...accounts];
  let mutableSelectedAccounts = selectedAccounts;

  if (!showPaidOutAccounts && hideAccountsWithNoBalance) {
    filteredAccounts = filterPaidAccountsWithNoBalance(filteredAccounts);
    mutableSelectedAccounts = filterPaidAccountsWithNoBalance(selectedAccounts);
  }

  if (!showPaidOutAccounts && hidePaidAccounts) {
    filteredAccounts = filterPaidAccounts(filteredAccounts);
    mutableSelectedAccounts = filterPaidAccounts(selectedAccounts);
  }

  const sortedAccounts = _sortBy(filteredAccounts, "name");
  const isMultiSelect = !isSingleSelect;
  const hasMultipleAccounts = accounts.length > 1;
  const dropdownTitle = isSingleSelect
    ? _get(mutableSelectedAccounts, "[0].name")
    : `${mutableSelectedAccounts.length} account(s) selected`;

  useEffect(() => {
    if (queryParamAccountId && showPaidOutAccounts) {
      dispatch(setSelectedAccounts([Number(queryParamAccountId)]));
    } else if (
      isSingleSelect &&
      hasMultipleAccounts &&
      selectedAccountIds.length !== 1
    ) {
      if (sortedAccounts.length === 0) {
        dispatch(setSelectedAccounts(["n/a"]));
      } else {
        dispatch(setSelectedAccounts([sortedAccounts[0].id]));
      }
    } else if (isSingleSelect) {
      if (filteredAccounts.length === 0) {
        dispatch(setSelectedAccounts(["n/a"]));
      } else {
        dispatch(setSelectedAccounts([filteredAccounts[0].id]));
      }
    }
  }, [
    isSingleSelect,
    hasMultipleAccounts,
    accounts,
    queryParamAccountId,
    showPaidOutAccounts,
  ]); //eslint-disable-line react-hooks/exhaustive-deps

  if (accounts.length === 0) {
    return <h2
        data-testid="no-accounts-header"
        className="h3 no-accounts-display"
    >
      No accounts available.
    </h2>
  }

  function isSelected(id: number) {
    return selectedAccountIds.includes(id);
  }

  function handleSelect(selectedId: number) {
    if (isSingleSelect) {
      dispatch(setSelectedAccounts([selectedId]));
    } else {
      dispatch(toggleAccount(selectedId));
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLLIElement>) {
    if (e.key === "Enter" || e.key === " ") {
      /**
       * Click the element instead of calling the onClick
       * so that the Horizon dropdown closes.
       */
      e.currentTarget.click();
    }
  }

  return (
    <>
      {hasMultipleAccounts ? (
        renderDropdown()
      ) : (
        <h2
          data-testid="single-account-header"
          className="h3 single-account-display"
        >
          {_get(accounts, "[0].name")}
        </h2>
      )}
    </>
  );

  function renderDropdown() {
    return (
      <div
        className="btn-group account-select-menu"
        data-testid="account-dropdown"
        role="group"
      >
        <button
          type="button"
          className="btn btn-default dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="dropdown-title" data-testid="dropdown-title">
            {dropdownTitle}
          </span>
          <Icon name="angle-down" />
        </button>
        <ul className="dropdown-menu account-list">
          {isMultiSelect && (
            <>
              <li
                onClick={() =>
                  allAccountsSelected
                    ? dispatch(deselectAll())
                    : dispatch(selectAll())
                }
                data-testid="default-option"
                tabIndex={0}
                onKeyDown={handleKeyDown}
                role="button"
              >
                {allAccountsSelected
                  ? "Deselect All Accounts"
                  : "Select All Accounts"}
              </li>
              <li role="separator" className="divider" />
            </>
          )}
          {!hideAccountNumber && (
            <li className={"account-dropdown-header"}>
              <span>Account name</span>
              <span>Account number</span>
            </li>
          )}
          {sortedAccounts.map((account) => (
            <AccountOption
              {...account}
              key={account.id}
              selected={isSelected(account.id)}
              handleSelect={handleSelect}
            />
          ))}
        </ul>
      </div>
    );
  }
}

function filterPaidAccounts(accounts: Account[]) {
  return accounts.filter(
    (account) =>
      account.payoutStatus !== PayoutStatus.COMPLETELY_PAID_OUT &&
      account.payoutStatus !== PayoutStatus.PAID_OUT_HAS_BALANCE
  );
}

function filterPaidAccountsWithNoBalance(accounts: Account[]) {
  return accounts.filter(
    (account) => account.payoutStatus !== PayoutStatus.COMPLETELY_PAID_OUT
  );
}

export default AccountDropdown;

import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import { Page } from "src/components/page";
import { Skeleton } from "../../../components/skeleton/skeleton";
import { getInstallmentFrequencyText } from "../../../models/distributions/installment-frequency";
import {
  getFormRedeferralSettings,
  getTimeRedeferralSettings,
  RedeferralInstruction,
} from "../../../models/redeferral-instruction";
import { RedeferralInstructionDto } from "../../../models/redeferral/redeferral-instruction-dto";
import { RedeferralStatus } from "../../../models/redeferral/redeferral-status";
import { accountRedeferralStatusesSelector } from "../../../selectors/redeferrals";
import { accountRedeferralStatusesStatusSelector } from "../../../selectors/status";
import {
  getRedeferralInstructionDTOsForAccount,
  saveRedeferralInstructionForAccount,
} from "../../../services/redeferral";
import { useSelector } from "../../../store/store";
import { DISTRIBUTION_REDEFERRAL_DISCLAIMER } from "../../../util/constants";
import RedeferChangeDisplay from "./redefer-change-display";
import RedeferNavHeader from "./redefer-nav-header";
import "./redefer-review-page.scss";
import { getRedeferralStateForAccount } from "./redefer-util";
import { isMMDDYYYYSlashDate } from "../../../util/date-util";

export default function RedeferReviewPage() {
  const { planId, empId, role } = useSelector((state) => state.session);
  const history = useHistory();

  const { search } = useLocation();
  const urlQueryParams = qs.parse(search);

  const queryDate = urlQueryParams.date ? urlQueryParams.date.toString() : null;
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");

  const newPayoutType = urlQueryParams.payoutType
    ? urlQueryParams.payoutType.toString()
    : null;
  const newInstallmentFrequency = urlQueryParams.installmentFrequency
    ? urlQueryParams.installmentFrequency.toString()
    : null;
  const newInstallmentCount = urlQueryParams.installmentCount
    ? urlQueryParams.installmentCount.toString()
    : null;

  const { accountId } = useParams();
  const allRedeferralStates: RedeferralStatus[] = useSelector(
    accountRedeferralStatusesSelector
  );

  const accountRedeferralState = getRedeferralStateForAccount(
    allRedeferralStates,
    accountId
  );

  useEffect(() => {
    if (buttonText === "Continue") {
      setButtonText("Saving redeferral...");
    } else {
      setButtonText("Continue");
    }
  }, [submitButtonClicked]);

  let newDate = queryDate;
  if (accountRedeferralState) {
    if (queryDate) {
      newDate = queryDate.replaceAll("-", "/");
    } else {
      newDate = accountRedeferralState.scheduledDistributionPayoutStartDate;
    }
  }

  const newMethod = getPayoutName(
    newPayoutType,
    newInstallmentFrequency,
    newInstallmentCount
  );

  let newName = "";
  if (accountRedeferralState) {
    newName = getNewAccountName(newDate, accountRedeferralState);
  }

  const [acknowledged, setAcknowledged] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [errorSaving, setErrorSaving] = useState<boolean>(false);

  function toggleAcknowledged(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    setAcknowledged(checked);
  }

  function refreshErrors() {
    const foundErrors: string[] = [];
    if (!acknowledged) {
      foundErrors.push(`Please check the acknowledgement before submitting.`);
    }
    setErrors(foundErrors);
    return foundErrors;
  }

  function getPayoutName(
    newPayoutType: string | null,
    newInstallmentFrequency: string | null,
    newInstallmentCount: string | null
  ) {
    switch (newPayoutType) {
      case "INSTALLMENTS":
        return `${getInstallmentFrequencyText(
          newInstallmentFrequency
        )} installments over ${newInstallmentCount} years`;
      case "LUMP_SUM":
        return `Lump Sum`;
      default:
        return accountRedeferralState?.scheduledDistributionMethod;
    }
  }

  function getConfirmURL(instructionId: number) {
    const accountId = accountRedeferralState?.accountId;
    return `/distributions/redeferral/confirm/account/${accountId}/instruction/${instructionId}`;
  }

  async function continueToConfirm() {
    const foundErrors = refreshErrors();
    if (foundErrors.length === 0) {
      setSubmitButtonClicked(true);
      await saveRedeferralInstruction();
    }
  }

  async function saveRedeferralInstruction() {
    const newRedeferralInstruction = mapDataToRedeferralInstruction();
    try {
      await saveRedeferralInstructionForAccount({
        planId: planId,
        empId: empId,
        accountId: accountId,
        role: role,
        data: newRedeferralInstruction,
      });
      await fetchRedeferralInstructions();
    } catch (e) {
      setSubmitButtonClicked(false);
      setErrorSaving(true);
    }
  }

  async function fetchRedeferralInstructions() {
    const result = await getRedeferralInstructionDTOsForAccount(
      role,
      accountId
    );
    if (result) {
      const instructionId = getLatestInstructionId(result);
      history.push(getConfirmURL(instructionId));
    }
  }

  function mapDataToRedeferralInstruction(): RedeferralInstruction | null {
    if (!accountRedeferralState) {
      return null;
    }
    const previousAccountName = accountRedeferralState.accountName.replace(
      " - 409A",
      ""
    );
    const accountName = newName !== "" ? newName : previousAccountName;
    const payoutStartDate = getTimeRedeferralSettings(queryDate);
    const payoutMethodSettings = getFormRedeferralSettings(
      newPayoutType,
      newInstallmentFrequency,
      newInstallmentCount
    );
    return {
      accountId,
      payoutStartDate,
      accountName,
      previousAccountName,
      payoutMethodType: payoutMethodSettings.payoutMethodType,
      installmentFrequency: payoutMethodSettings.installmentFrequency,
      installmentYears: payoutMethodSettings.installmentYears,
    };
  }

  function getLatestInstructionId(
    redeferralInstructionDtos: RedeferralInstructionDto[]
  ): number {
    let max = 0;
    redeferralInstructionDtos.forEach((instruction) => {
      if (instruction.id > max) {
        max = instruction.id;
      }
    });
    return max;
  }

  return (
    <>
      <Page name={"redefer-begin"} loading={false}>
        <RedeferNavHeader
          stepNumber={2}
          accountRedeferralState={accountRedeferralState}
        />
        <Skeleton
          selectors={[accountRedeferralStatusesStatusSelector]}
          height={90}
          width={180}
        >
          {accountRedeferralState && (
            <>
              <div className="row" data-testid="review-row">
                <div className="col-md-12">
                  <p>
                    Please review the distribution election(s) below before you
                    submit your request.
                  </p>
                  <div className="alert alert-warning">
                    <p>
                      All future deferrals and contributions allocated to this
                      account will be subject to this revised deferral election
                      unless you allocate into a different account at your next
                      enrollment period.
                    </p>
                  </div>
                  <RedeferChangeDisplay
                    newDate={newDate}
                    newMethod={newMethod}
                    newName={newName}
                    accountRedeferralStatus={accountRedeferralState}
                  />

                  <div className="styled-checkbox display-flex">
                    <input
                      className="custom-checkbox"
                      type="checkbox"
                      id="acknowledge"
                      data-testid="acknowledge"
                      checked={acknowledged}
                      onChange={toggleAcknowledged}
                    />
                    <label htmlFor="acknowledge" className="bold">
                      I confirm the information and authorize Principal Life
                      Insurance Company to process my request. Additionally, I
                      understand Principal Life Insurance Company
                      representatives cannot provide tax or legal advice and
                      that it is my responsibility to obtain advice from my
                      financial, tax, and/or legal advisors and to understand
                      the terms of the plan and the tax consequences of this
                      revised deferral election.
                    </label>
                  </div>
                  <hr />
                </div>
                {errors.length > 0 && (
                  <div className="col-md-12" data-testid="review-errors">
                    <div className="alert alert-danger">
                      <p>Please fix errors before continuing:</p>
                      <ul>
                        {errors.map((error) => (
                          <li>{error}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {errorSaving && (
                  <div className="col-md-12" data-testid="review-errors">
                    <div className="alert alert-danger">
                      <p>Error saving redeferral instruction.</p>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <section id="redeferral-begin-buttons" className="pull-right">
                    <Link
                      className="btn btn-default util-margin-left-5"
                      to={`/distributions/redeferral/begin/account/${accountRedeferralState.accountId}`}
                    >
                      Back
                    </Link>
                    <button
                      className="btn btn-primary util-margin-left-5"
                      data-testid="submit-button"
                      onClick={() => continueToConfirm()}
                      disabled={buttonText === "Saving redeferral..."}
                    >
                      {buttonText}
                    </button>
                  </section>
                </div>
                <div className="col-md-12">
                  <hr />
                  <p>{DISTRIBUTION_REDEFERRAL_DISCLAIMER}</p>
                </div>
              </div>
            </>
          )}
        </Skeleton>
      </Page>
    </>
  );
}

export function getNewAccountName(
  newDate: string | null,
  accountRedeferralState: RedeferralStatus
) {
  let newName = "";
  const nameWithout409a = accountRedeferralState.accountName.replace(
    " - 409A",
    ""
  );
  if (
    !accountRedeferralState.accountName.toLowerCase().includes("re-deferred")
  ) {
    newName = nameWithout409a + " - Re-deferred";
  } else {
    newName = nameWithout409a;
  }

  const splitName = newName.split(" ");
  for (let i = 0; i <= splitName.length; i++) {
    if ( isMMDDYYYYSlashDate(splitName[i]) && Date.parse(splitName[i])) {
      if (newDate !== null) {
        splitName[i] = newDate;
      }
    }
  }
  newName = splitName.join(" ");
  return newName;
}

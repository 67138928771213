import React, { useEffect, useState } from "react";
import { enrollmentToolsStatus, useStatuses } from "../../selectors/status";
import { fetchEnrollmentTools } from "../../reducers/enrollment-tools/enrollment-tools";
import { fetchViewEnrollmentToolsDocument } from "../../reducers/view-enrollment-tools-document/view-enrollment-tools-document";
import { useAppDispatch, useSelector } from "../../store/store";
import { Page, PageHeader } from "src/components/page";
import { AsyncStatus } from "src/models/async-status";
import { ToolLink } from "src/models/plan-info-and-summary/tool-link";
import { buildLinkForEstimatedBenefitCalculator } from "src/util/nq-participant-link-builder";

export default function EnrollmentToolsPage() {
  const { enrollmentTools } = useSelector((state) => state.enrollmentTools);
  const { viewEnrollmentToolsDocument } = useSelector(
    (state) => state.viewEnrollmentToolsDocument
  );
  const { planId, empId, role } = useSelector((state) => state.session);
  const { isLoadingStatus } = useStatuses([enrollmentToolsStatus]);
  const [currentToolLink, setCurrentToolLink] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchEnrollmentTools({ planId, empId, role }));
    if (
      viewEnrollmentToolsDocument &&
      viewEnrollmentToolsDocument.status === AsyncStatus.error
    ) {
      setError(true);
    }
  }, [dispatch, planId, empId, role, viewEnrollmentToolsDocument]);

  const viewEnrollmentToolDocuments = (
    extension: string,
    resourceId: number,
    toolLinkName: string
  ) => {
    dispatch(
      fetchViewEnrollmentToolsDocument({
        planId,
        empId,
        extension,
        resourceId,
        role,
      })
    );
    setCurrentToolLink(toolLinkName);
  };

  const isBenefitCalc = (toolLink: ToolLink) => {
    return toolLink.linkName === "Estimated Benefit Calculator";
  };

  const nonQualifiedPlanner = (toolLink: ToolLink) => {
    return toolLink.linkName === "Nonqualified Deferred Compensation Planner";
  };

  const isClickableLink = (toolLink: ToolLink) => {
    setError(false);
    return (
      isBenefitCalc(toolLink) ||
      nonQualifiedPlanner(toolLink) ||
      !!toolLink.outputLink
    );
  };

  const getUrl = (toolLink: ToolLink) => {
    if (isBenefitCalc(toolLink)) {
      return buildLinkForEstimatedBenefitCalculator(planId, empId, role);
    } else if (nonQualifiedPlanner(toolLink)) {
      return "https://nq.individuals.principal.com/nq-compensation-planner/#/";
    } else if (!!toolLink.outputLink) {
      return toolLink.urlLink;
    }
    return "#";
  };

  const getTarget = (toolLink: ToolLink) => {
    if (nonQualifiedPlanner(toolLink)) {
      return "_blank";
    }
    return "_self";
  };

  return (
    <Page name={"enrollment-tools"} loading={isLoadingStatus}>
      {viewEnrollmentToolsDocument.status === AsyncStatus.error && error ? (
        <div className="alert alert-danger alert-icon" role="alert">
          {currentToolLink} is unavailable at this time. Please call for
          assistance.
        </div>
      ) : (
        ""
      )}
      <PageHeader name={"enrollment tools"}>Enrollment Tools</PageHeader>

      {
        <span>
          The following resources are available for your review. Some of these
          resources are in PDF format. In order to view a PDF file, you must
          have Adobe Acrobat Reader installed on your computer.
        </span>
      }
      <ul>
        {enrollmentTools.toolLinks.map((toolLink) => (
          <li className="util-margin-top-15">
            <a
              href={getUrl(toolLink)}
              onClick={() =>
                isClickableLink(toolLink)
                  ? true
                  : viewEnrollmentToolDocuments(
                      toolLink.extension,
                      toolLink.resourceId,
                      toolLink.linkName
                    )
              }
              target={getTarget(toolLink)}
            >
              {toolLink.linkName}
            </a>
            &nbsp;&nbsp;<span>{toolLink.extension}</span>
            <div>
              <span>{toolLink.linkDescription}</span>
            </div>
          </li>
        ))}
      </ul>
      <div className="util-margin-top-15">
        <span>
          The amounts listed on the website represent a measure of your benefits
          under a nonqualified benefit agreement between you and your plan
          sponsor. It is not a representation that your plan sponsor holds any
          assets for paying these benefits.
        </span>

        <div className="util-margin-top-15">
          These resources are for general information only and are not intended
          to provide specific advice or recommendations to any individual. All
          situations and investors described are hypothetical. Please consult
          your attorney, accountant or financial or tax advisor with regard to
          your individual situation.
        </div>
      </div>
    </Page>
  );
}

import React, { useEffect, useState } from "react";
import RedeferNavHeader from "../redefer-nav-header";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import { accountRedeferralStatusesStatusSelector } from "../../../../selectors/status";
import { Page } from "../../../../components/page/page";
import { getRedeferralStateForAccount } from "../redefer-util";
import { RedeferralStatus } from "../../../../models/redeferral/redeferral-status";
import { useSelector } from "../../../../store/store";
import { accountRedeferralStatusesSelector } from "../../../../selectors/redeferrals";
import { Link, useParams } from "react-router-dom";
import { DISTRIBUTION_REDEFERRAL_DISCLAIMER } from "../../../../util/constants";
import { ScheduledDistributionForRedeferral } from "../../../../models/distributions/scheduled-distribution-event";
import { useHistory } from "react-router";
import { getSessionStorageKey } from "../../../../util/redeferral-util";
import RedeferIndividualPaymentReviewTable from "./redefer-individual-payment-review-table";
import { saveRedeferralInstructions } from "../../../../services/redeferral";
import { RedeferralInstruction } from "../../../../models/redeferral-instruction";

export default function RedeferIndividualPaymentReview() {
  const history = useHistory();
  const { planId, empId, role } = useSelector((state) => state.session);

  const [acknowledged, setAcknowledged] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [buttonText, setButtonText] = useState("Submit");
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [errorSaving, setErrorSaving] = useState<boolean>(false);

  const { accountId } = useParams();

  const allRedeferralStates: RedeferralStatus[] = useSelector(
    accountRedeferralStatusesSelector
  );

  const scheduledDistributions = useSelector(
    (state) =>
      state.distributions.scheduledDistributions.scheduledDistributionEvents
  ).filter((distribution) => distribution.accountId === Number(accountId));

  const accountRedeferralState = getRedeferralStateForAccount(
    allRedeferralStates,
    accountId
  );

  useEffect(() => {
    if (buttonText === "Submit" && submitButtonClicked) {
      setButtonText("Saving redeferral...");
    } else {
      setButtonText("Submit");
    }
  }, [submitButtonClicked]);

  function getScheduledDistributions(): ScheduledDistributionForRedeferral[] {
    const distributions: ScheduledDistributionForRedeferral[] = [];
    scheduledDistributions.forEach((distribution) => {
      const dist = sessionStorage.getItem(
        getSessionStorageKey(distribution.accountId, distribution.installNum)
      );
      if (dist !== null) {
        const parsedObject = JSON.parse(dist);
        distributions.push(parsedObject);
      }
    });
    return distributions;
  }

  function toggleAcknowledged(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    setAcknowledged(checked);
  }

  async function continueToConfirm() {
    const foundErrors = refreshErrors();
    if (foundErrors.length === 0) {
      setSubmitButtonClicked(true);
      await saveRedeferrals();
    }
  }

  async function saveRedeferrals() {
    const redeferralInstructions = mapDataToRedeferralInstruction();
    try {
      await saveRedeferralInstructions({
        planId: planId,
        empId: empId,
        accountId: accountId,
        role: role,
        data: redeferralInstructions,
      });
      history.push(getConfirmURL());
    } catch (e) {
      setSubmitButtonClicked(false);
      setErrorSaving(true);
    }
  }

  function mapDataToRedeferralInstruction(): RedeferralInstruction[] | null {
    if (!accountRedeferralState) {
      return null;
    }
    const instructions: RedeferralInstruction[] = [];
    const redeferredDistributions = getScheduledDistributions().filter(
      (dist) => dist.isRedeferred
    );

    redeferredDistributions.forEach((distribution) => {
      const instruction = {
        accountId: distribution.accountId,
        payoutStartDate: distribution.newDistributionDate,
        installmentNumber: distribution.installNum,
        previousPayoutStartDate: distribution.scheduledDate,
        isIndividualFlow: true,
      } as RedeferralInstruction;
      instructions.push(instruction);
    });

    return instructions;
  }

  function refreshErrors() {
    const foundErrors: string[] = [];
    if (!acknowledged) {
      foundErrors.push(`Please check the acknowledgement before submitting.`);
    }
    setErrors(foundErrors);
    return foundErrors;
  }

  function getConfirmURL() {
    const accountId = accountRedeferralState?.accountId;
    return `/distributions/redeferral/individual/confirm/account/${accountId}`;
  }

  return (
    <>
      <Page name={"redefer-review"} loading={false}>
        <RedeferNavHeader
          stepNumber={2}
          accountRedeferralState={accountRedeferralState}
        />
        <Skeleton
          selectors={[accountRedeferralStatusesStatusSelector]}
          height={90}
          width={180}
        >
          {accountRedeferralState && (
            <>
              <div className="row" data-testid="review-row">
                <div className="col-md-12">
                  <p>
                    Please review the distribution election(s) below before you
                    submit your request.
                  </p>
                  <p>
                    <strong>{accountRedeferralState.accountName}</strong>
                  </p>

                  <RedeferIndividualPaymentReviewTable
                    scheduledDistributions={getScheduledDistributions()}
                  />

                  <div className="styled-checkbox display-flex">
                    <input
                      className="custom-checkbox"
                      type="checkbox"
                      id="acknowledge"
                      data-testid="acknowledge"
                      checked={acknowledged}
                      onChange={toggleAcknowledged}
                    />
                    <label htmlFor="acknowledge" className="bold">
                      I confirm the information and authorize Principal Life
                      Insurance Company to process my request. Additionally, I
                      understand Principal Life Insurance Company
                      representatives cannot provide tax or legal advice and
                      that it is my responsibility to obtain advice from my
                      financial, tax, and/or legal advisors and to understand
                      the terms of the plan and the tax consequences of this
                      revised deferral election.
                    </label>
                  </div>
                  <hr />
                </div>
                {errors.length > 0 && (
                  <div className="col-md-12" data-testid="review-errors">
                    <div className="alert alert-danger">
                      <p>Please fix errors before continuing:</p>
                      <ul>
                        {errors.map((error) => (
                          <li>{error}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {errorSaving && (
                  <div className="col-md-12" data-testid="review-errors">
                    <div className="alert alert-danger">
                      <p>Error saving redeferral.</p>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <section
                    id="redeferral-review-buttons"
                    className="pull-right"
                  >
                    <Link
                      className="btn btn-default util-margin-left-5"
                      to={`/distributions/redeferral/begin/account/${accountRedeferralState.accountId}`}
                    >
                      Back
                    </Link>
                    <button
                      className="btn btn-primary util-margin-left-5"
                      data-testid="submit-button"
                      onClick={() => continueToConfirm()}
                      disabled={buttonText === "Saving redeferral..."}
                    >
                      {buttonText}
                    </button>
                  </section>
                </div>
                <div className="col-md-12">
                  <hr />
                  <p>
                    The Estimated Distribution amount(s) listed are based upon
                    your current account balance and do not factor in a vesting
                    schedule, if applicable. Your actual distribution may be
                    different than the amount(s) listed above.
                  </p>
                  <p>{DISTRIBUTION_REDEFERRAL_DISCLAIMER}</p>
                </div>
              </div>
            </>
          )}
        </Skeleton>
      </Page>
    </>
  );
}

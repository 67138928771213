import axios from "axios";
import { apiHost } from "../util/constants";

function buildUrl(path: string) {
  const formattedPath = path.charAt(0) === "/" ? path : `/${path}`;
  return `${apiHost}/rnq/services/salesforcewrapperservice${formattedPath}`;
}

type GetParams = {
  role?: string;
  planId?: number;
  empId?: number;
  messageTypes?: string;
};

export async function get(path: string, params: GetParams = {}) {
  const apiToken = localStorage.getItem("accessToken");
  const { data } = await axios.get(buildUrl(path), {
    headers: {
      authorization: `Bearer ${apiToken}`,
    },
    params,
  });
  return data;
}

export async function post(path: string, postBody: object, params?: object) {
  const apiToken = localStorage.getItem("accessToken");
  const { data } = await axios.post(buildUrl(path), postBody, {
    headers: {
      authorization: `Bearer ${apiToken}`,
    },
    params,
  });
  return data;
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { Account } from "../models/account";
import { getAccounts } from "../services/accounts";
import { PayoutStatus } from "../models/payout-status";

export const fetchAccounts = createAsyncThunk(
  "fetch-accounts",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getAccounts({ planId, empId, role });
  }
);

type State = {
  available: Account[];
  availableIds: number[];
  selected: Account[];
  selectedIds: number[];
  showPaidOutAccounts: boolean;
  paidOutAccountsExist: boolean;
  allAccountsPaidOut: boolean;
  status: AsyncStatus;
};

const initialState: State = {
  available: [],
  availableIds: [],
  selected: [],
  selectedIds: [],
  showPaidOutAccounts: false,
  paidOutAccountsExist: false,
  allAccountsPaidOut: false,
  status: AsyncStatus.not_fetched,
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setShowPaidOutAccounts: (state, action: { payload: boolean }) => {
      return { ...state, showPaidOutAccounts: action.payload };
    },
    setSelectedAccounts: (
      state,
      action: { payload: number[]; type: string }
    ) => {
      const selectedIds: number[] = action.payload;
      const selected = state.available.filter(({ id }) =>
        selectedIds.includes(id)
      );
      return { ...state, selectedIds, selected };
    },
    selectAll: (state, action: { payload: undefined; type: string }) => {
      return {
        ...state,
        selected: state.available,
        selectedIds: state.availableIds,
      };
    },
    deselectAll: (state, action: { payload: undefined; type: string }) => {
      return { ...state, selected: [], selectedIds: [] };
    },
    toggleAccount: (state, action: { payload: number; type: string }) => {
      let accountIds: number[];
      const id: number = action.payload;
      if (state.selectedIds.includes(id)) {
        accountIds = state.selectedIds.filter(
          (selectedId) => id !== selectedId
        );
      } else {
        accountIds = [...state.selectedIds, id];
      }
      const accounts = state.available.filter((account) =>
        accountIds.includes(account.id)
      );
      return { ...state, selectedIds: accountIds, selected: accounts };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchAccounts.fulfilled,
      (state, action: { payload: Account[]; type: string }) => {
        const available = action.payload;
        const availableIds = available.map((account) => account.id);
        const allAccountsPaidOut = areAllAccountsPaidOut(available);

        return {
          ...state,
          available,
          availableIds,
          selected: available,
          selectedIds: availableIds,
          showPaidOutAccounts: true,
          paidOutAccountsExist: areTherePaidOutAccounts(available),
          allAccountsPaidOut,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchAccounts.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export function areTherePaidOutAccounts(availableAccounts: Account[]): boolean {
  let doPaidOutAccountsExist = false;
  availableAccounts.forEach((account) => {
    if (
      account.payoutStatus === PayoutStatus.PAID_OUT_HAS_BALANCE ||
      account.payoutStatus === PayoutStatus.COMPLETELY_PAID_OUT
    ) {
      doPaidOutAccountsExist = true;
    }
  });
  return doPaidOutAccountsExist;
}

export function areAllAccountsPaidOut(availableAccounts: Account[]): boolean {
  let allAccountsPaidOut = true;
  availableAccounts.forEach((account) => {
    if (
      account.payoutStatus === PayoutStatus.NOT_IN_PAYOUT ||
      account.payoutStatus === PayoutStatus.IN_PAYOUT
    ) {
      allAccountsPaidOut = false;
    }
  });
  return allAccountsPaidOut;
}

export const {
  setSelectedAccounts,
  selectAll,
  deselectAll,
  toggleAccount,
  setShowPaidOutAccounts,
} = accountsSlice.actions;
export default accountsSlice.reducer;

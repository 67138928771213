import React from "react";
import SortableTable from "../../../components/table/sortable-table";
import { ScheduledDistributionPagePayment } from "../../../models/distributions/distribution-payment";
import "../distribution-scheduled/scheduled-distribution-table.scss";
import {
  contributionRateStatus,
  investmentsStatusSelector,
  sessionStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import {
  buildAccountNameNodeColumn,
  buildAccountTypeColumn,
  buildDistributionDateColumn,
  buildDistributionMethodColumn,
  buildEstimatedDistributionColumn,
  buildInstallmentColumn,
  ColumnWithClass,
} from "./build-schedule-distributions-columns-util/build-schedule-distributions-columns-utils";
import { useSelector } from "../../../store/store";
import { hideAccountNumberSelector } from "../../../selectors/plan-info";

type Props = {
  distributions: ScheduledDistributionPagePayment[];
};

export default function ScheduledDistributionsTable(props: Props) {
  const { distributions } = props;
  const { isLoadingStatus } = useStatuses([
    investmentsStatusSelector,
    sessionStatusSelector,
    contributionRateStatus,
  ]);
  const hideAccountNumber = useSelector(hideAccountNumberSelector);

  if (!isLoadingStatus && distributions.length === 0) {
    return <p>Currently there are no scheduled distributions.</p>;
  }

  distributions.forEach((distribution) => {
    const { accountName, accountId } = distribution;
    distribution.accountNameNode = hideAccountNumber
      ? `${accountName}`
      : `${accountName} \nAccount number: ${accountId}`;
  });

  const columns: ColumnWithClass[] = [
    buildDistributionDateColumn(),
    buildAccountNameNodeColumn(hideAccountNumber),
    buildAccountTypeColumn(),
    buildDistributionMethodColumn(),
    buildInstallmentColumn(true),
    buildEstimatedDistributionColumn(true),
  ];

  return (
    <div className="scheduled-distributions-table">
      <SortableTable columns={columns} data={distributions} hasFooter={false} />
    </div>
  );
}

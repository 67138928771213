import React, { useState } from "react";
import { useLocation } from "react-router";
import { getDebuggerSetting } from "../../util/debug-util";

interface Props {
  children: any;
}

// DebugCard components only show when ?debug=true is in the URL
export default function DebugSpan(props: Props) {
  const { children } = props;
  const { search } = useLocation();
  const isDebuggerOn = getDebuggerSetting(search);
  const [showElem, setShowElem] = useState(true);

  return (
    <>
      {isDebuggerOn && showElem && (
        <>
          <span className="bg-light-blue util-padding-horz-10 util-padding-vert-10">
            DEBUG | {children}
            <span
              className="util-margin-left-10"
              onClick={() => setShowElem(!showElem)}
            >
              <i className="fa fa-times"></i>
            </span>
          </span>
        </>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import SkipLink from "../skip-link/skip-link";
import { getHeader } from "../../services/page-elements";
import "./site-nav.scss";

export default function SiteNav() {
  const [header, setHeader] = useState();

  useEffect(() => {
    fetchHeader().catch((err) => setHeader(""));
  });

  async function fetchHeader() {
    const data = await getHeader();
    setHeader(data);
  }

  if (window.location.pathname.includes("/landing")) {
    return null;
  }

  if (!header) {
    return <div className="site-header-skeleton" />;
  }

  return (
    <>
      <SkipLink />
      <div dangerouslySetInnerHTML={{ __html: header }} />
    </>
  );
}
